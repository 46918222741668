<template>
  <div>
    <h2 class="text-center">Super Admin Dashboard</h2>
    <div class="col-md-6" style="margin: 0 auto">
      <FlashMessage :error="error" class="text-center" />
      <h3 class="text-center">Client List</h3>
      <table class="table table-striped table-bordered table-dark">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="client in clients" :key="client.id">
            <td>{{ client.name }}</td>
            <td>{{ client.email }}</td>
            <td>
              <button class="btn-sm btn-secondary">
                Edit Client
              </button>
              <button class="btn-sm btn_primary" @click="ssologin(client.id)">
                Login
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { getError } from "@/utils/helpers";
import FlashMessage from "@/components/FlashMessage";
import AuthService from "@/services/AuthService";
import store from "@/store/index";
export default {
  name: "SuperadminDashboard",
  components: {
    FlashMessage,
  },
  data() {
    return {
      clients: [],
      error : null,
    };
  },
  methods: {
    fetchClients() {
      store.dispatch("auth/getClients").then((response) => {
        this.clients = response;
        //console.log(response);
      });
    },
    ssologin(clientId) {
      const payload = {
        user_id : clientId
      };
      this.error = null;
      try {
        AuthService.ssoLogin(payload).then(() => {
          this.$store.dispatch("auth/getAuthUser");
          this.$router.push("/dashboard");
        });        
      } catch (error) {
        this.error = getError(error);
      }
    },
  },
  created: function () {
    this.fetchClients();
  },
};
</script>